@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');


* {
    font-family: 'Raleway', sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #1D1F22;
}